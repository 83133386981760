import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

import { updateParentProfile } from '../../../actions/shopActions';

import Field from '../../checkout/shared/forms/field';

import './styles.styl';

class ProfileInfoForm extends Component {
  state = {
    gender: this.props.profile.gender || '',
    firstname: this.props.profile.firstname || '',
    lastname: this.props.profile.lastname || '',
    phone_number: this.props.profile.phone_number || '',
    email: this.props.email || '',
  };

  onInputChange = (name, value) => {
    this.setState(state => ({
      ...state,
      [name]: value,
    }));
  };

  onGenderChange = ({ currentTarget }) => {
    this.setState(state => ({
      ...state,
      gender: currentTarget.dataset.gender,
    }));
  };

  saveProfile = () => {
    const { gender, firstname, lastname, phone_number } = this.state;
    const { updateParentProfile } = this.props;

    updateParentProfile({
      gender,
      firstname,
      lastname,
      phone_number,
    });
  };

  render() {
    const { gender, firstname, lastname, phone_number, email } = this.state;
    const { wordings, profileErrors } = this.props;

    return (
      <>
        <h5>{wordings.personnal_informations}</h5>
        <form>
          <div
            className={`input-radio-wrapper${
              profileErrors.gender && profileErrors.gender.length
                ? ' error'
                : ''
            }`}
          >
            {wordings.civility}
            <label className="radio-container">
              {wordings.madam}
              <input
                onChange={this.onGenderChange}
                data-gender="F"
                checked={gender && gender === 'F'}
                type="checkbox"
              />
              <span className="checkmark" />
            </label>
            <label className="radio-container">
              {wordings.mister}
              <input
                onChange={this.onGenderChange}
                data-gender="M"
                checked={gender && gender === 'M'}
                type="checkbox"
              />
              <span className="checkmark" />
            </label>
          </div>
          <div className="two-columns">
            <Field
              label={wordings.firstname}
              wrapperClassName={'stacked'}
              onChange={this.onInputChange}
              value={firstname}
              name="firstname"
              inputType="text"
              errors={profileErrors.firstname}
            />
            <Field
              label={wordings.lastname}
              wrapperClassName={'stacked'}
              onChange={this.onInputChange}
              value={lastname}
              name="lastname"
              inputType="text"
              errors={profileErrors.lastname}
            />
          </div>
          <Field
            label={wordings.phone}
            wrapperClassName={'stacked'}
            onChange={this.onInputChange}
            value={phone_number}
            name="phone_number"
            inputType="text"
            errors={profileErrors.phone_number}
          />
          <Field
            label={wordings.email}
            wrapperClassName={'stacked'}
            onChange={this.onInputChange}
            value={email}
            name="email"
            inputType="text"
            inputClassName="email-input"
            errors={profileErrors.email}
            disabled
          />
          <div className="button-container">
            <div className="button" onClick={this.saveProfile}>
              {wordings.save}
            </div>
          </div>
        </form>
      </>
    );
  }
}

ProfileInfoForm.defaultProps = {
  profile: {},
  profileErrors: {},
};

ProfileInfoForm.propTypes = {
  profileErrors: PropTypes.object.isRequired,
  profile: PropTypes.object,
  email: PropTypes.string.isRequired,
  wordings: PropTypes.object.isRequired,
  updateParentProfile: PropTypes.func.isRequired,
};

const mapStateToProps = ({ account: { profileErrors, profile, email } }) => ({
  profileErrors,
  profile,
  email,
});

export default connect(
  mapStateToProps,
  {
    updateParentProfile,
  }
)(ProfileInfoForm);
