/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';

import { tryToken } from '../../../actions/shopActions';

import Layout from '../../../components/layout/layout';
import {
  NavBar,
  Menu,
  Footer,
  Basket,
  pageWithNavigation,
  AccountNav,
} from '../../../components/common';
import ProfileComponent from '../../../components/account/profile/profileInfo';

class ProfilePage extends Component {
  componentWillMount() {
    const { tryTokenAction, access_token } = this.props;

    if (access_token === '') {
      navigate('');
    } else {
      tryTokenAction(access_token);
    }
  }

  render() {
    const {
      data: {
        wordings: {
          fr: { profile },
        },
      },
    } = this.props;

    return (
      <Layout title={profile.page_title} description={profile.description}>
        <NavBar withLogo basketClassName="yellow" />
        <Basket />
        <Menu />
        <AccountNav wordings={profile} active="account_infos">
          <ProfileComponent wordings={profile} />
        </AccountNav>
        <Footer />
      </Layout>
    );
  }
}

export const query = graphql`
  query ProfilePageQuery {
    wordings {
      fr {
        profile {
          page_title
          description
          profile_link
          orders_link
          personnal_informations
          shipping_address
          billing_address
          use_different_address
          modify
          save
          civility
          mister
          madam
          firstname
          lastname
          email
          phone
          password
          shipping_firstname_label
          shipping_lastname_label
          shipping_company_label
          shipping_address_label
          shipping_informations_label
          shipping_city_label
          shipping_postalcode_label
          shipping_country_label
          shipping_state_label
          shipping_phone_label
          billing_firstname_label
          billing_lastname_label
          billing_company_label
          billing_address_label
          billing_informations_label
          billing_city_label
          billing_postalcode_label
          billing_country_label
          billing_state_label
          billing_phone_label
        }
      }
    }
  }
`;

ProfilePage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  tryTokenAction: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
};

const mapStateToProps = ({ account: { access_token } }) => ({
  access_token,
});

export default connect(
  mapStateToProps,
  { tryTokenAction: tryToken }
)(pageWithNavigation(ProfilePage));
